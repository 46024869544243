import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e0c7c28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_echo = _resolveComponent("message-echo")!
  const _component_message_error = _resolveComponent("message-error")!
  const _component_message_video = _resolveComponent("message-video")!
  const _component_message_stats = _resolveComponent("message-stats")!
  const _component_message_text = _resolveComponent("message-text")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["message-group", {'flex-row-reverse': group[0].isUserMessage()}]),
      key: index
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (message, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (_ctx.isEchoMessage(message))
              ? (_openBlock(), _createBlock(_component_message_echo, {
                  key: 0,
                  class: _normalizeClass({'last': _ctx.isLast(group, index)}),
                  message: message
                }, null, 8, ["class", "message"]))
              : (_ctx.isErrorMessage(message))
                ? (_openBlock(), _createBlock(_component_message_error, {
                    key: 1,
                    class: _normalizeClass({'last': _ctx.isLast(group, index)}),
                    message: message
                  }, null, 8, ["class", "message"]))
                : (_ctx.isVideoMessage(message))
                  ? (_openBlock(), _createBlock(_component_message_video, {
                      key: 2,
                      class: _normalizeClass({'last': _ctx.isLast(group, index)}),
                      message: message
                    }, null, 8, ["class", "message"]))
                  : (_ctx.isStatsMessage(message))
                    ? (_openBlock(), _createBlock(_component_message_stats, {
                        key: 3,
                        class: _normalizeClass({'last': _ctx.isLast(group, index)}),
                        message: message
                      }, null, 8, ["class", "message"]))
                    : (_openBlock(), _createBlock(_component_message_text, {
                        key: 4,
                        class: _normalizeClass({'last': _ctx.isLast(group, index)}),
                        message: message
                      }, null, 8, ["class", "message"]))
          ], 64))
        }), 128))
      ])
    ], 2))
  }), 128))
}