
import { defineComponent, markRaw } from 'vue'
import Player from '@vimeo/player'

import { MessageVideoModel } from '@/types/Message'

import MessageWrapper from '@/components/pages/chat/messages/MessageWrapper.vue'

export default defineComponent({
  name: 'MessageVideo',
  props: {
    message: {
      type: MessageVideoModel,
      required: true
    }
  },
  components: {
    MessageWrapper
  },
  data () {
    return {
      player: null
    } as {
      player: Player | null
    }
  },
  computed: {
    activeVideo (): Player {
      return this.$store.getters.activeVideo
    }
  },
  watch: {
    activeVideo (player: Player) {
      if (this.player !== player) {
        // eslint-disable-next-line no-unused-expressions
        this.player?.pause()
      }
    }
  },
  mounted () {
    this.player = markRaw(
      new Player(
        this.$refs.player as HTMLElement,
        {
          url: this.message.url,
          autopause: true,
          autoplay: true,
          dnt: true,
          title: false,
          playsinline: false,
          responsive: true
        }
      )
    )

    this.$store.commit('onVideoCreated', this.player)

    this.player.on('ended', () => {
      this.$store.commit('onVideoEnded', this.player)
    })
    this.player.on('timeupdate', (status: { seconds: number, duration: number }) => {
      if (status.seconds + 10 >= status.duration) {
        this.$store.commit('onVideoEnding', this.player)
      }
    })
    this.player.on('play', () => {
      this.$store.commit('onVideoPlay', this.player)
    })
  },
  unmounted () {
    // eslint-disable-next-line no-unused-expressions
    this.player?.unload()
  }
})
