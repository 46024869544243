
import { defineComponent } from 'vue'
import DOMPurify from 'dompurify'

import config from '@/configs/config'
import { MessageErrorModel } from '@/types/Message'

import MessageWrapper from '@/components/pages/chat/messages/MessageWrapper.vue'

export default defineComponent({
  name: 'MessageError',
  props: {
    message: {
      type: MessageErrorModel,
      required: true
    }
  },
  components: {
    MessageWrapper
  },
  methods: {
    getSafeHtml (line: string) {
      return DOMPurify.sanitize(line, config.CHAT.DOM_PURIFY)
    }
  }
})
