
import { defineComponent } from 'vue'

import { MessageReply } from '@/types/Message'

export default defineComponent({
  name: 'ButtonListInput',
  emits: [
    'updated'
  ],
  props: {
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      observer: undefined,
      debounce: false
    } as {
      observer?: ResizeObserver,
      debounce: boolean
    }
  },
  computed: {
    replies (): MessageReply[] {
      return this.$store.getters.replies
    },
    buttons (): string[] {
      return this.replies.map((reply: MessageReply) => {
        return reply.getButtonText()
      })
    }
  },
  mounted () {
    const buttons = (this.$refs.buttons ?? []) as HTMLElement[]
    // eslint-disable-next-line no-unused-expressions
    ((buttons.length > 1) ? buttons[0].parentElement : buttons[0])?.focus()

    if (!this.observer) {
      this.observer = new ResizeObserver(() => {
        if (!this.debounce) {
          this.$emit('updated')
          this.debounce = true
          setTimeout(() => {
            this.debounce = false
          }, 500)
        }
      })
      this.observer.observe(this.$refs.input as HTMLElement)
    }
  },
  beforeUnmount () {
    // eslint-disable-next-line no-unused-expressions
    this.observer?.disconnect()
  },
  methods: {
    onClick (index: number) {
      const reply = this.replies[index]
      this.$store.commit('onMessageSend', { payload: reply.payload, message: reply.getMessageText() })
    }
  }
})
