
import { defineComponent } from 'vue'
import DOMPurify from 'dompurify'

import config from '@/configs/config'
import { MessageEchoModel, MessageModel } from '@/types/Message'

import MessageWrapper from '@/components/pages/chat/messages/MessageWrapper.vue'

export default defineComponent({
  name: 'MessageEcho',
  props: {
    message: {
      type: MessageEchoModel,
      required: true
    }
  },
  components: {
    MessageWrapper
  },
  computed: {
    messages (): MessageModel[] {
      return this.$store.getters.messages
    },
    show (): boolean {
      return this.messages[this.messages.length - 2] === this.message
    },
    waitingForBotResponse (): boolean {
      return this.$store.getters.waitingForBotResponse
    }
  },
  methods: {
    reinput () {
      this.$store.commit('onMessageSend', { payload: '/undo', message: false })
    },
    getSafeHtml (line: string) {
      return DOMPurify.sanitize(line, config.CHAT.DOM_PURIFY)
    }
  }
})
