
import { defineComponent, PropType } from 'vue'
import { Chart, ChartConfiguration, ChartDataset, ChartType, TooltipCallbacks } from 'chart.js'
import { AnnotationOptions } from 'chartjs-plugin-annotation'

const GRAPH_TYPE: ChartType = 'bubble'

export default defineComponent({
  name: 'BubbleGraph',
  props: {
    data: {
      type: Array as PropType<Array<{
        tooltip: string | string[],
        highlight: boolean,
        values: Array<{
          x: number,
          y: number,
          r: number
        }>
      }>>,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    xAverage: {
      type: Number,
      required: false
    },
    yAverage: {
      type: Number,
      required: false
    },
    xTitle: {
      type: String,
      required: false
    },
    yTitle: {
      type: String,
      required: false
    },
    xPrefix: {
      type: String,
      required: false
    },
    xSuffix: {
      type: String,
      required: false
    },
    yPrefix: {
      type: String,
      required: false
    },
    ySuffix: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      chart: {},
      config: {
        type: GRAPH_TYPE,
        data: {
          datasets: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {},
            y: {}
          },
          plugins: {
            legend: {
              display: false
            },
            annotation: {
              annotations: []
            },
            tooltip: {
              callbacks: {}
            },
            title: {}
          }
        }
      }
    } as {
      chart: Chart | unknown,
      config: ChartConfiguration
    }
  },
  methods: {
    getAverageLine (value: number, axis: 'x' | 'y'): AnnotationOptions {
      const line = {
        type: 'line',
        drawTime: 'beforeDatasetsDraw',
        borderColor: 'rgb(0, 0, 0, 0.5)',
        borderWidth: 2,
        borderDash: [10, 10],
        label: {
          enabled: true,
          content: 'mean',
          position: axis === 'x' ? 'start' : 'end',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          color: 'rgba(0, 0, 0, 0.5)',
          yAdjust: axis === 'y' ? -10 : -5,
          xAdjust: axis === 'x' ? 10 : 5,
          rotation: axis === 'x' ? 90 : 0
        }
      } as AnnotationOptions

      if (axis === 'x') {
        line.xMin = line.xMax = value
      } else {
        line.yMin = line.yMax = value
      }
      return line
    }
  },
  mounted () {
    const options = this.config.options as any

    options.plugins.datalabels = {
      display: false
    }

    if (this.title) {
      options.plugins.title = {
        display: true,
        text: this.title
      }
    }

    if (this.xAverage) {
      options.plugins.annotation.annotations.push(
        this.getAverageLine(this.xAverage, 'x')
      )
    }
    if (this.yAverage) {
      options.plugins.annotation.annotations.push(
        this.getAverageLine(this.yAverage, 'y')
      )
    }

    if (this.xTitle) {
      options.scales.x.title = {
        display: true,
        text: this.xTitle
      }
    }
    if (this.yTitle) {
      options.scales.y.title = {
        display: true,
        text: this.yTitle
      }
    }

    if (this.xPrefix || this.xSuffix) {
      options.scales.x.ticks = {
        callback: (value: number) => {
          return (this.xPrefix ?? '') + value.toLocaleString() + (this.xSuffix ?? '')
        }
      }
    }
    if (this.yPrefix || this.ySuffix) {
      options.scales.y.ticks = {
        callback: (value: number) => {
          return (this.yPrefix ?? '') + value.toLocaleString() + (this.ySuffix ?? '')
        }
      }
    }

    this.config.data.datasets = this.data.map((item): ChartDataset => {
      return {
        data: item.values,
        backgroundColor: item.highlight ? 'rgba(110, 170, 230, 1)' : 'rgba(170, 170, 170, 1)'
      }
    }) as ChartDataset[]

    options.plugins.tooltip.callbacks = {
      afterTitle: (item: any) => {
        return this.data[item[0].datasetIndex]?.tooltip
      }
    } as TooltipCallbacks<typeof GRAPH_TYPE>

    this.chart = new Chart(
      this.$refs.canvas as HTMLCanvasElement,
      this.config as ChartConfiguration
    )
  }
})
