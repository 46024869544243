
import { defineComponent, PropType } from 'vue'
import { Chart, ChartConfiguration, ChartType, TooltipCallbacks } from 'chart.js'
import { Context } from 'chartjs-plugin-datalabels'

const GRAPH_TYPE: ChartType = 'bar'

export default defineComponent({
  name: 'BarGraph',
  props: {
    title: {
      type: String,
      required: false
    },
    data: {
      type: Array as PropType<Array<{
        tooltip: string | string[],
        highlight: boolean,
        value: {
          x: number,
          y: number
        }
      }>>,
      required: true
    },
    xTitle: {
      type: String,
      required: false
    },
    yTitle: {
      type: String,
      required: false
    },
    xPrefix: {
      type: String,
      required: false
    },
    xSuffix: {
      type: String,
      required: false
    },
    yPrefix: {
      type: String,
      required: false
    },
    ySuffix: {
      type: String,
      required: false
    },
    barLabel: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      chart: null,
      config: {
        type: GRAPH_TYPE,
        data: {
          datasets: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {},
            y: {}
          },
          plugins: {
            scales: {
              y: {
                min: 0,
                max: 100
              }
            },
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {}
            }
          }
        }
      }
    } as {
      chart: Chart | unknown,
      config: ChartConfiguration
    }
  },
  mounted () {
    const options = this.config.options as any

    options.plugins.datalabels = {
      display: false
    }

    if (this.barLabel) {
      options.plugins.datalabels = {
        display: true,
        anchor: 'end',
        align: 'end',
        offset: -4,
        formatter: (value: number, context: Context) => {
          return this.data[context.dataIndex].value.y.toLocaleString()
        }
      }
    }

    if (this.title) {
      options.plugins.title = {
        display: true,
        text: this.title
      }
    }

    if (this.xTitle) {
      options.scales.x.title = {
        display: true,
        text: this.xTitle
      }
    }
    if (this.yTitle) {
      options.scales.y.title = {
        display: true,
        text: this.yTitle
      }
    }

    if (this.xPrefix || this.xSuffix) {
      options.scales.x.ticks = {
        callback: (value: number) => {
          return (this.xPrefix ?? '') + value.toLocaleString() + (this.xSuffix ?? '')
        }
      }
    }
    if (this.yPrefix || this.ySuffix) {
      options.scales.y.ticks = {
        callback: (value: number) => {
          return (this.yPrefix ?? '') + value.toLocaleString() + (this.ySuffix ?? '')
        }
      }
    }

    this.config.data.datasets = [{
      backgroundColor: this.data.map((item) => item.highlight ? 'rgba(110, 170, 230, 1)' : 'rgba(170, 170, 170, 1)'),
      data: this.data.map((item) => ({ ...item.value }))
    }]

    options.plugins.tooltip.callbacks = {
      afterTitle: (item) => {
        return this.data[item[0].dataIndex]?.tooltip
      },
      title: (item) => {
        return ''
      }
    } as TooltipCallbacks<typeof GRAPH_TYPE>

    this.chart = new Chart(
      this.$refs.canvas as HTMLCanvasElement,
      this.config
    )
  }
})
