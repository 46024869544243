import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24841d3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_wrapper = _resolveComponent("message-wrapper")!

  return (_openBlock(), _createBlock(_component_message_wrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["align-items-center message-container", {
        'float-end': _ctx.message.isUserMessage(),
        'user-message': _ctx.message.isUserMessage()
      }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message.lines, (line, index) => {
          return (_openBlock(), _createElementBlock("p", {
            key: index,
            innerHTML: _ctx.getSafeHtml(line)
          }, null, 8, _hoisted_1))
        }), 128))
      ], 2)
    ]),
    _: 1
  }))
}