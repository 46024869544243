
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TextInput',
  props: {
    placeholder: {
      type: String,
      required: true
    },
    button: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      text: ''
    }
  },
  mounted () {
    // eslint-disable-next-line no-unused-expressions
    (this.$refs.input as HTMLElement)?.focus()
  },
  methods: {
    onSubmit () {
      if (this.text !== null && this.text.trim() !== '') {
        this.$store.commit('onMessageSend', { payload: this.text })
        this.text = ''
      }
    }
  }
})
