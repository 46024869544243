
import { defineComponent, PropType } from 'vue'

import {
  MessageVideoModel, MessageStatsModel, MessageModel, MessageErrorModel, MessageEchoModel
} from '@/types/Message'

import MessageEcho from '@/components/pages/chat/messages/MessageEcho.vue'
import MessageError from '@/components/pages/chat/messages/MessageError.vue'
import MessageStats from '@/components/pages/chat/messages/MessageStats.vue'
import MessageText from '@/components/pages/chat/messages/MessageText.vue'
import MessageVideo from '@/components/pages/chat/messages/MessageVideo.vue'

export default defineComponent({
  name: 'MessageList',
  emits: [
    'updated'
  ],
  props: {
    messages: {
      type: Array as PropType<Array<MessageModel>>,
      required: true
    }
  },
  components: {
    MessageEcho,
    MessageError,
    MessageStats,
    MessageText,
    MessageVideo
  },
  computed: {
    groups (): MessageModel[][] {
      return this.messages.reduce(
        (groups: MessageModel[][], message: MessageModel, index: number, messages: MessageModel[]) => {
          if (index > 0 && messages[index - 1].sender === message.sender) {
            groups[groups.length - 1].push(message)
          } else {
            groups.push([message])
          }
          return groups
        }, [])
    }
  },
  updated () {
    this.$emit('updated')
  },
  methods: {
    isEchoMessage (message: MessageModel): boolean {
      return message instanceof MessageEchoModel
    },
    isErrorMessage (message: MessageModel): boolean {
      return message instanceof MessageErrorModel
    },
    isVideoMessage (message: MessageModel): boolean {
      return message instanceof MessageVideoModel
    },
    isStatsMessage (message: MessageModel): boolean {
      return message instanceof MessageStatsModel
    },
    isLast (array: unknown[], index: number): boolean {
      return array.length - 1 === index
    }
  }
})
