
import { defineComponent } from 'vue'

import { MessageModel, MessageReply, MessageVideoModel } from '@/types/Message'

import MessageList from '@/components/pages/chat/messages/MessageList.vue'
import TextInput from '@/components/pages/chat/inputs/TextInput.vue'
import ButtonListInput from '@/components/pages/chat/inputs/ButtonListInput.vue'

export default defineComponent({
  name: 'Chat',
  components: {
    MessageList,
    TextInput,
    ButtonListInput
  },
  computed: {
    messages (): MessageModel[] {
      return this.$store.getters.messages
    },
    replies (): MessageReply[] {
      return this.$store.getters.replies
    },
    showReplyBar (): boolean {
      return !!this.replies.length
    },
    waitingForBotResponse (): boolean {
      return this.$store.getters.waitingForBotResponse
    },
    inputHidden (): boolean {
      return this.$store.getters.inputHidden
    },
    videoEnded (): boolean {
      return this.$store.getters.videoEnded
    },
    videoEnding (): boolean {
      return this.$store.getters.videoEnding
    }
  },
  mounted () {
    this.$store.dispatch('openSocket')
  },
  beforeUnmount () {
    this.$store.dispatch('closeSocket')
  },
  watch: {
    videoEnded (ended: boolean) {
      const lastMessage = this.messages[this.messages.length - 1]
      if (ended && lastMessage instanceof MessageVideoModel) {
        this.$store.commit('onMessageSend', { payload: '/continue', message: false })
      }
    },
    videoEnding (ending: boolean) {
      if (ending && this.$store.getters.inputHidden) {
        this.$store.commit('onToggleInput', true)
      }
    },
    messages: {
      handler: function () {
        const lastMessage = this.messages[this.messages.length - 1]
        if (lastMessage instanceof MessageVideoModel) {
          this.$store.commit('onToggleInput', false)
        }
      },
      deep: true
    }
  },
  updated () {
    this.updated()
  },
  methods: {
    updated () {
      const chat = this.$refs.chat as HTMLElement
      if (chat) {
        chat.scrollTop = chat.scrollHeight
      }
    }
  }
})
