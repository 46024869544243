import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2af37de1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["placeholder", "disabled"]
const _hoisted_3 = { class: "input-group-append border-left" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      type: "text",
      class: "form-control",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onSubmit()), ["enter"])),
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.text]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "btn btn-outline-secondary",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSubmit())),
        disabled: _ctx.disabled
      }, _toDisplayString(_ctx.button), 9, _hoisted_4)
    ])
  ]))
}