
import { defineComponent } from 'vue'
import Modal from 'bootstrap/js/dist/modal'
import DOMPurify from 'dompurify'

import config from '@/configs/config'
import { MessageStatsModel } from '@/types/Message'

import MessageWrapper from '@/components/pages/chat/messages/MessageWrapper.vue'
import BarGraph from '@/components/pages/chat/graphs/BarGraph.vue'
import BubbleGraph from '@/components/pages/chat/graphs/BubbleGraph.vue'

const REGEX_GRAPH = /\[graph:(\d+)\]/

export default defineComponent({
  name: 'MessageStats',
  props: {
    message: {
      type: MessageStatsModel,
      required: true
    }
  },
  components: {
    MessageWrapper,
    BarGraph,
    BubbleGraph
  },
  data () {
    return {
      modal: undefined,
      lines: []
    } as {
      modal: Modal | undefined,
      lines: {[name: string]: unknown}[]
    }
  },
  mounted () {
    this.lines = this.message.lines.map((line) => {
      const matches = line.match(REGEX_GRAPH)
      if (matches && matches.length === 2) {
        const graphs = this.message.data.graphs as { type: string }[]
        const graph = graphs[Number(matches[1])]
        if (graph) {
          return {
            type: graph.type,
            graph: graph
          }
        }
      }
      return {
        type: 'message',
        text: line
      }
    })
    this.showModal()
  },
  beforeUnmount () {
    // eslint-disable-next-line no-unused-expressions
    this.modal?.dispose()
  },
  methods: {
    showModal () {
      if (!this.modal) {
        const modal = this.$refs.modal as HTMLElement
        this.modal = new Modal(modal, {})
        const onModalHide = () => {
          modal.removeEventListener('hide.bs.modal', onModalHide)
          this.$store.commit('onMessageSend', { payload: '/continue', message: false })
        }
        modal.addEventListener('hide.bs.modal', onModalHide)
      }
      this.modal.show()
    },
    getSafeHtml (line: string) {
      return DOMPurify.sanitize(line, config.CHAT.DOM_PURIFY)
    }
  }
})
