import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-046195df"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "player",
  ref: "player"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_wrapper = _resolveComponent("message-wrapper")!

  return (_openBlock(), _createBlock(_component_message_wrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["align-items-center message-container video", {
        'float-end': _ctx.message.isUserMessage(),
        'user-message': _ctx.message.isUserMessage()
      }])
      }, [
        _createElementVNode("div", _hoisted_1, null, 512)
      ], 2)
    ]),
    _: 1
  }))
}