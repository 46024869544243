import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-680c57b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "input",
  class: "d-grid gap-2 d-md-flex align-items-center"
}
const _hoisted_2 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "list-transition" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (label, index) => {
          return (_openBlock(), _createElementBlock("button", {
            ref_for: true,
            ref: "buttons",
            class: "btn btn-outline-primary",
            type: "button",
            key: index,
            disabled: _ctx.disabled,
            onClick: ($event: any) => (_ctx.onClick(index))
          }, _toDisplayString(label), 9, _hoisted_2))
        }), 128))
      ]),
      _: 1
    })
  ], 512))
}